.moduloActualizacionContainer {
    width: 100%;
    height: 280px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 2px 13px 54px #695F9714;
    border-radius: 30px;
    opacity: 1;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
}

.rowModuloActualizacion {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 2px;
}

.colModuloActualizacion {
    padding: 2px;
}

.rowModuloActualizacion1 {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    gap: 2px;
}

.rowModuloActualizacion2 {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 2px;
}


.inputModuloActualizacion {
    width: 100%;
    height: 32px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EBEBEB;
    border-radius: 19px;
    opacity: 1;
    padding-left: 10px;
}

.btnActualizarModuloActualizacion {
    width: 100%;
    height: 32px;
    background-color: #42C3BA;
    border: none;
    border-radius: 20px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
}

.inputModuloActualizacionDesc {
    width: 100%;
    height: 60px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EBEBEB;
    border-radius: 19px;
    opacity: 1;
    padding-left: 10px;
}










.moduloActualizacionContainerNuevo {
    width: 100%;
    height: 431px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 2px 13px 54px #695F9714;
    border-radius: 30px;
    opacity: 1;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
}






.moduloEstadosContenedor {
    width: 100%;
    max-height: 400px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 5px 31px 54px #695F9726;
    border-radius: 30px;
    opacity: 1;
    margin-top: 22px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    overflow: auto
}



@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@600&display=swap');

.tituloModuloEstados {
    text-align: left;
    letter-spacing: -0.47px;
    color: #000000;
    opacity: 1;
    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 19px;
    line-height: 29px;

}

.modificacionModuloEstados {
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    letter-spacing: 0px;
    color: #7A86A1;
    opacity: 1;
    line-height: 60px;
}




.botonActualizarEstados {
    width: 93px;
    height: 42px;
    border: none;
    color: #FFFFFF;
    background: #42C3BA 0% 0% no-repeat padding-box;
    box-shadow: 1px 8px 29px #695F9724;
    border-radius: 21px;
    opacity: 1;
    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    line-height: 60px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}







.containerEstados {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.grid-item {
    display: flex;
    align-items: center;
}

.labelEstados {
    font-size: 10px;
    margin-right: 10px;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    color: #000000;
    line-height: 40px;
    opacity: 1;
    min-width: 160px;
}

.form-control {
    flex-grow: 1;
}