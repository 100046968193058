.moduloIzqChoques {
    height: 1013px;
    width: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 2px 13px 54px #695F9714;
    border-radius: 30px;
    opacity: 1;
    padding-right: 30px;
    padding-left: 30px;
    padding-top: 20px;
}

.moduloEstadoChoques {
    height: 543px;
    width: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 5px 31px 54px #695F9726;
    border-radius: 30px;
    opacity: 1;
}