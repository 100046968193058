.tituloTablaCargas {
    padding-top: 28px;
    font-size: 32px;
    color: #000000;
    font-weight: 500;
}
.sectionTablaCargaContainer {
    
}
.sectionTablaCarga {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 2px 13px 54px #695F9714;
    border-radius: 30px;
    opacity: 1;
    height: 84vh;
    width: 100%;
}

.btnBusquedaContainer {
    width: 529px;
    height: 50px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EBEBEB;
    border-radius: 19px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 23px;
}

.btnBusqueda {
    border: none;
    background: none;
    height: 100%;
    width: 100%;
    text-align: left;
    letter-spacing: 0px;
    color: #7A86A1;
}

.btnBusqueda:focus {
    outline: none;
}

.selectTabla {
    border: none;
    width: 100%;
    text-align: left;
    letter-spacing: 0px;
    color: #7A86A1;
}

.selectTabla:focus {
    border: none;
    outline: none;
}

.selectTabla:hover {
    outline: none;
}

.select1 {
    width: 183px;
    margin-left: 16px;
    height: 66px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EBEBEB;
    border-radius: 19px;
    opacity: 1;
}

.select2 {
    margin-left: 16px;
    width: 133px;
    height: 66px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EBEBEB;
    border-radius: 19px;
    opacity: 1;
}

.select3 {
    width: 183px;
    margin-left: 100px;
    height: 66px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EBEBEB;
    border-radius: 19px;
    opacity: 1;
}

.btn1 {
    margin-left: 31px;
    width: 67px;
    height: 66px;
    background: #42C3BA 0% 0% no-repeat padding-box;
    box-shadow: 1px 8px 29px #695F9724;
    border-radius: 19px;
    opacity: 1;
    font-size: 30px;
}

.btn2 {
    margin-left: 29px;
    width: 67px;
    height: 66px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EBEBEB;
    border-radius: 19px;
    opacity: 1;
    font-size: 30px;
}

.tablaCarga {
    margin-top: 20px;
}

.tablaCargaHeader {
    opacity: 1;
}

.tablaCargaBody {
    height: 75vh;
    margin-top: 0px;
    display: block;
    overflow-y: auto;
    table-layout: fixed;
}

.tablaCargaHeaderText {
    color: #C0BDCC;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}

.tablaCargaBodyText1 {
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
}

.tablaCargaBodyText2 {
    color: #7A86A1;
    font-size: 16px;
    font-weight: 300;
    text-align: center;
}

.tablaCargaBodyText3 {
    color: #7A86A1;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
}

.tablaCargaBodyText4 {
    color: #7A86A1;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
}



.circleVerde {
    width: 12px;
    height: 12px;
    background: #42C3BA 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 100%;
}

.circleRojo {
    width: 12px;
    height: 12px;
    background: red 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 100%;
}

.circleVioleta {
    width: 12px;
    height: 12px;
    background: #CD49AE 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 100%;
}

.circleGris {
    width: 12px;
    height: 12px;
    background: #CCC9D9 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 100%;
}





.btnFiltroTablaCarga1 {
    width: 306px;
    height: 60px;
    border-radius: 20px;
    opacity: 1;
    font-size: 20px;
    font-weight: 400;
    color: #7A86A1;
    padding-left: 24.29px;
}

.btnFiltroTablaCarga2 {
    height: 60px;
    background: #42C3BA 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;
    font-size: 19px;
    color: #7A86A1;
}


.btnBusquedaContainerFiltro {
    height: 49px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 2px 3px 6px #30131314;
    border-radius: 11px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.containerBotonBusquedaFiltro {
    width: 37px;
    height: 37px;
    background: #6C708C6B 0% 0% no-repeat padding-box;
    border-radius: 11px;
    opacity: 0.2;
    display: flex;
    justify-content: center;
    align-items: center;
}


.filtroCargaColContainer {
    margin-top: 19px;
}

.filtroCargaCol {
    height: 49px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 2px 3px 6px #695F9714;
    border-radius: 11px;
    opacity: 1;
}

.filtroCargaCol2 {
    height: 49px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 2px 3px 6px #695F9714;
    border-radius: 11px;
    opacity: 1;
}

.filtroCargaCol3 {
    width: 148px;
    height: 49px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 2px 3px 6px #695F9714;
    border-radius: 11px;
    opacity: 1;
    margin-left: 33px;
}


.rmsc .dropdown-container {
    border: none !important;
    display: flex;
    align-items: center;
}











thead,
tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}




.textoLabelFiltro {
    font-size: 12px;
}



.SpinnerInTheMiddle {
    position: fixed;
    top: 20%;
    left: 50%;
}



.custom-paginate {
    list-style: none;
    display: flex;
    padding: 0;
}

.custom-paginate li {
    margin: 0 4px;
}

.custom-paginate li a {
    padding: 8px 16px;
    text-decoration: none;
    color: black; 
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s, border 0.3s;
}

.custom-paginate li.active a {
    background-color: #42C3BA; 
    color: white; 
}

.custom-paginate li a:hover {
    background-color: #42C3BA;
    color: white; 
}

.custom-paginate li.previous a,
.custom-paginate li.next a {
    color: #42C3BA;
}

.custom-paginate li.previous a:hover,
.custom-paginate li.next a:hover {
    background-color: #42C3BA;
    color: white; 
}

.custom-paginate li.disabled a {
    color: #ddd;
    cursor: not-allowed;
}





div[role="option"][data-value="separator"] {
    pointer-events: none; /* Evita que el separador sea clickeable */
    color: gray;
    font-style: italic;
    background-color: transparent;
}

.tablaValoresContractualesBody {
    color: #000000;
    font-size: 16px;
    font-weight: 400;
}