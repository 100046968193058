@font-face {
    font-family: 'bebas';
    src: url('../../fonts/BebasNeue-Book.otf') format('opentype');
}
@font-face {
    font-family: 'bebasBold';
    src: url('../../fonts/BebasNeue-Bold.otf') format('opentype');
}
@font-face {
    font-family: 'bebasReg';
    src: url('../../fonts/BebasNeue-Regular.otf') format('opentype');
}
@font-face {
    font-family: 'bebasThin';
    src: url('../../fonts/BebasNeue-Thin.otf') format('opentype');
}

.titulo {
    font-family: 'bebasBold', sans-serif;
    font-weight: bold;
    letter-spacing: 2px;
    font-size: 20px;
    color: #161616;
}

.titulo2 {
    font-family: 'bebasReg', sans-serif;
    font-weight: 600;
    letter-spacing: 1px;
    font-size: 20px;
    color: #161616;
}
.titulo3 {
    font-family: 'bebasReg', sans-serif;
    letter-spacing: 1px;
    font-weight: 500;
    font-size: 16px;
    color: #161616;
}
.titulo4 {
    font-family: 'bebasReg', sans-serif;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 16px;
    color: #161616;
}
.subTitulo2 {
    font-family: 'bebasReg', sans-serif;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 20px;
    color: #161616;
}
.subTitulo3 {
    font-family: 'bebasReg', sans-serif;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 20px;
    text-align: center;
    color: #161616;
}
.subTitulo3 {
    font-family: 'bebasReg', sans-serif;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 14px;
    color: #161616;
}
.bordeNegro {
    width: 100%;
    height: 2px;
    color: black;
    background-color: black;
}