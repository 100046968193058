.marginCalendar {
    margin-top: 60px;
}

.react-calendar {
    width: 50%;
    max-width: 100%;
    border: none;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
}

.react-calendar--doubleView {
    width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
    width: 50%;
    margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}

.react-calendar button:enabled:hover {
    cursor: pointer;
}

.react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
}

.react-calendar__navigation button {
    min-width: 44px;
    background: none;
}

.react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font: inherit;
    font-weight: 400;
    padding: 0.5em;
    color: #7A86A1;
    font-size: 11px;
    text-decoration: none;
}


.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font: inherit;
    font-size: 0.75em;
    font-weight: bold;
}




.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
    color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}

.react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
    font: inherit;
    font-size: 0.833em;
}

.react-calendar__tile:disabled {
    background-color: #f0f0f0;
    color: #ababab;
}

.react-calendar__month-view__days__day--neighboringMonth:disabled,
.react-calendar__decade-view__years__year--neighboringDecade:disabled,
.react-calendar__century-view__decades__decade--neighboringCentury:disabled {
    color: #cdcdcd;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
}

.react-calendar__tile--now {
    color: blue;
    border-radius: 100%;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #42C3BA;
    border-radius: 100%;
}

.react-calendar__tile--hasActive {
    background: #42C3BA;
    border-radius: 100%;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #42C3BA;
    border-radius: 100%;
}

.react-calendar__tile--active {
    background: #42C3BA;
    color: black;
    border-radius: 100%;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #42C3BA;
}

.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}

.rmsc .dropdown-container {
    border: none;
}





.my-custom-calendar {
    border: none;
    width: 100%;
    font-family: 'Arial, sans-serif';
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.my-custom-calendar .react-calendar__tile {
    border-radius: 8px;
    transition: background-color 0.3s, color 0.3s;
}

.my-custom-calendar .react-calendar__tile:enabled:hover,
.my-custom-calendar .react-calendar__tile:enabled:focus {
    background-color: #42C3BA;
    color: white;
}

.my-custom-calendar .react-calendar__tile--now {
    color: #0d6efd;
    border-radius: 8px;
}

.my-custom-calendar .react-calendar__tile--active {
    background: #42C3BA;
    color: white;
    border-radius: 8px;
}


.my-custom-calendarB {
    border: none;
    width: 100%;
    font-family: 'Arial, sans-serif';
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #42C3BA;
}

.my-custom-calendarB .react-calendar__tile {
    border-radius: 8px;
    transition: background-color 0.3s, color 0.3s;
}

.my-custom-calendarB .react-calendar__tile:enabled:hover,
.my-custom-calendarB .react-calendar__tile:enabled:focus {
    background-color: #42C3BA;
    color: white;
}

.my-custom-calendarB .react-calendar__tile--now {
    color: #0d6efd;
    border-radius: 8px;
}

.my-custom-calendarB .react-calendar__tile--active {
    background: #42C3BA;
    color: white;
    border-radius: 8px;
}