.offCanvas {
    height: 100vh;
    width: 60vw;
    position: fixed; 
    background-color: white;
    top: 0;
    left: 0;
    overflow-y: scroll;
    padding-bottom: 50px;
}
.offCanvasDer {
    height: 100vh;
    width: 60vw;
    position: fixed; 
    background-color: white;
    top: 0;
    right: 0;
    overflow-y: scroll;
    padding-bottom: 50px;
}
.btnSs {
    font-size: 30px;
}