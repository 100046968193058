.moduloEstadoChoques {
    width: 100%;
    height: 543px;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 5px 31px 54px #695F9726;
    border-radius: 30px;
    opacity: 1;
    padding-left: 48px;
    padding-top: 32.5px;
}

.btnActualizarChoques {
    width: 122px;
    height: 42px;
    /* UI Properties */
    background: #42C3BA 0% 0% no-repeat padding-box;
    box-shadow: 1px 8px 29px #695F9724;
    border-radius: 21px;
    opacity: 1;
    border: none;
}

.inputObservacionChoque {
    width: 544px;
    height: 72px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EBEBEB;
    border-radius: 19px;
    opacity: 1;
}

.moduloEstadoChoquesTransitorioChat {
    height: 520px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 5px 31px 54px #695F9726;
    border-radius: 22px;
    opacity: 1;
    width: 100%;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 20px;
    margin-top: 20px;
}

.moduloEstadoChoquesTransitorio2 {
    margin-top: 20px;
    width: 100%;
    height: 273px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 2px 13px 54px #695F9714;
    border-radius: 30px;
    opacity: 1;
    padding-left: 30px;
    padding-right: 30px;
}