@font-face {
    font-family: 'bebas';
    src: url('./fonts/BebasNeue-Book.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

::-webkit-scrollbar {
    width: 5px;       /* Ancho del scroll vertical */
    height: 5px;      /* Altura del scroll horizontal */
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px; 
}

::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 20px; 
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
} 



.my-scrollbar {
    overflow-y: scroll;
}

.my-scrollbar::-webkit-scrollbar {
    width: 5px; 
}

.my-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.my-scrollbar::-webkit-scrollbar-thumb {
    background: #42C3BA; 
    border-radius: 10px;
}

.my-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #42c3baa9;
}



