.divFechasCargasStro {
    margin-top: 20px;
}

.seccion1StroHeader {
    height: 350px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 2px 13px 54px #695F9714;
    border-radius: 30px;
    opacity: 1;
}

.seccion2StroHeader {
    padding-top: 212px;
    padding-left: 220px;
}

.docuHeaderStroHeader {
    padding-top: 20px;
}

.btnDescargarDocuHeaderStroContainer {
    width: 158px;
    height: 45px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 1px 8px 29px #695F9724;
    border-radius: 19px;
    opacity: 1;
    letter-spacing: 0px;
    color: #42C3BA;
    font-size: 14px;
    font-weight: 600;
    border: none;
    margin-left: 21.5px;
}

.seccion3StroHeader {
    margin-left: 49px;
    width: 388px;
    height: 266px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 9px 13px 54px #695F9714;
    border-radius: 30px;
    opacity: 1;
}

.infoStroContainer {
    padding-top: 20px;
}

.infoStroTabla1 {
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
    padding-top: 20px;
    height: 585px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 2px 13px 54px #695F9714;
    border-radius: 30px;
    opacity: 1;
}

.infoStroTabla2Container {
    margin-left: 91px;
}

.infoStroTabla2 {
    height: 920px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 2px 13px 54px #695F9714;
    border-radius: 30px;
    opacity: 1;
    margin-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
}

.tituloStroTabla {
    padding-top: 19.05px;
    letter-spacing: 0px;
    color: #000000;
    font-size: 28px;
    font-weight: 400;
}

.tituloStroTabla2 {
    letter-spacing: 0px;
    color: #000000;
    font-size: 24px;
    font-weight: 500;
}

.infoStroElemento {
    margin-top: 20px;
}

.infoStroElemento1 {
    margin-top: 14px;
}

.labelStro {
    letter-spacing: 0px;
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    width: 120px;
}
.labelStroCol4 {
    letter-spacing: 0px;
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    width: 80px;
}
.labelStro1 {
    letter-spacing: 0px;
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    width: 60px;
}

.inputStroVigencia {
    width: 100%;
    height: 48px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EBEBEB;
    border-radius: 19px;
    opacity: 1;
}

.inputStroVigenciaCol6 {
    width: 100%;
    height: 48px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EBEBEB;
    border-radius: 17px;
    opacity: 1;
}
.inputStroVigenciaCol4 {
    width: 100%;
    height: 48px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EBEBEB;
    border-radius: 17px;
    opacity: 1;
}
.inputStroVigenciaTextarea {
    width: 100%;
    height: 86px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EBEBEB;
    border-radius: 19px;
    opacity: 1;
    overflow-y: auto;
}
.inputStroVigenciaTextarea2 {
    width: 100%;
    height: 180px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EBEBEB;
    border-radius: 19px;
    opacity: 1;
    overflow-y: auto;
}
.labelStro2 {
    letter-spacing: 0px;
    color: #000000;
    font-size: 9px;
    font-weight: 500;
    margin-left: 105.1px;
}

.inputStro {
    margin-left: 10.95px;
    width: 237px;
    height: 34px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EBEBEB;
    border-radius: 19px;
    opacity: 1;
}

.inputStro1 {
    margin-left: 10.95px;
    width: 521px;
    height: 35px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EBEBEB;
    border-radius: 19px;
    opacity: 1;
}

.btnVerTodo1Stro {
    width: 122px;
    height: 42px;
    background: #42C3BA 0% 0% no-repeat padding-box;
    box-shadow: 1px 8px 29px #695F9724;
    border-radius: 21px;
    margin-left: 30px;
}

.btnSharepoint {
    margin-left: 30px;
}
.btnVerTodo3Stro {
    width: 122px;
    height: 42px;
    background: #42C3BA 0% 0% no-repeat padding-box;
    box-shadow: 1px 8px 29px #695F9724;
    border-radius: 21px;
    margin-left: 30px;
}

.btnVerTodo2Stro {
    width: 80%;
    height: 42px;
    background: #42C3BA 0% 0% no-repeat padding-box;
    box-shadow: 1px 8px 29px #695F9724;
    border-radius: 21px;
    margin-top: 20px;
}
.btnVerTodo2StroIgual {
    width: 100%;
    height: 42px;
    background: red 0% 0% no-repeat padding-box;
    box-shadow: 1px 8px 29px #695F9724;
    border-radius: 21px;
    margin-left: 30px;
    margin-top: 20px;
}
.btnVerTodo4Stro {
    width: 140px;
    height: 42px;
    background: #42C3BA 0% 0% no-repeat padding-box;
    box-shadow: 1px 8px 29px #695F9724;
    border-radius: 21px;
    margin-left: 30px;
    margin-top: 35px;
}

.btnVerTodo5Stro {
    width: 140px;
    height: 42px;
    background: #42C3BA 0% 0% no-repeat padding-box;
    box-shadow: 1px 8px 29px #695F9724;
    border-radius: 21px;
    margin-left: 200px;
    margin-top: 35px;
}

.arrowStro {
    width: 66px;
    height: 66px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 1px 8px 29px #695F972E;
    border-radius: 15px;
    opacity: 1;
    font-size: 30px;
    color: #000000;
}


.insideArrowStro {
    width: 140px;
    height: 50px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 1px 8px 29px #695F972E;
    border-radius: 15px;
    opacity: 1;
    letter-spacing: 0px;
    color: #7A86A1;
    font-size: 19px;
    font-weight: 500;
}

.btnCargaArrowStro {
    width: 129px;
    height: 50px;
    background: #42C3BA 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;
}

.btnTablaArrowStro {
    width: 149px;
    height: 50px;
    opacity: 1;
    margin: auto;
    color: #7A86A1;
}

.iconGridArrow {
    font-size: 19px;

}

.iconMensaje {
    font-size: 24px;
}

.textCargaBtnArrowStro {
    font-size: 19px;
    font-weight: 400;
}

.divNotificacionesStro {
    width: 120px;
    height: 59px;
    border-radius: 20px;
    opacity: 1;
    margin: auto;
    color: #7A86A1;
    margin-top: 12px;
}

.spanNotifiaciones {
    width: 33px;
    height: 33px;
    background: #F96767 0% 0% no-repeat padding-box;
    border: 2px solid #FBF9FE;
    border-radius: 8px;
    opacity: 1;
    position: relative;
    top: -10px;
}

.divMensajeStro {
    width: 59px;
    height: 59px;
    background: #42C3BA 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;
    margin: auto;
    margin-top: 12px;
    margin-right: 185.36px;
}

.spanMensaje {
    width: 33px;
    height: 33px;
    background: #F96767 0% 0% no-repeat padding-box;
    border: 2px solid #FBF9FE;
    border-radius: 8px;
    opacity: 1;
    position: absolute;
    top: 36px;
    left: 1698px;
}









.divUltimoChatStro {
    margin-top: 10px;
}

.ultimoMensajeContainerStro {
    padding-left: 40px;
    padding-top: 15px;
    width: 1578px;
    height: 132px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 30px;
    box-shadow: 2px 13px 54px #695F9714;
    opacity: 1;
}

.mensajeCronoliaStro {
    width: 100%;
    height: 90px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EBEBEB;
    border-radius: 16px;
    opacity: 1;
}

.sendContainer {
    margin-left: 25px;
}

.sendMessageBtnContainer {
    width: 35px;
    height: 35px;
    background: #42C3BA 0% 0% no-repeat padding-box;
    box-shadow: 2px 14px 32px #6149CD1A;
    border-radius: 22px;
    opacity: 1;
}

.sendMessageUserContainer {
    width: 120px;
    border-radius: 4px;
    opacity: 1;
    border: 1px solid #42C3BA;
}



.inputStroAmpliacionTextarea {
    width: 100%;
    height: 66px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EBEBEB;
    border-radius: 19px;
    opacity: 1;
}





















.div1StroHeader {
    padding-top: 45px;
}
.div12StroHeader {
    padding-top: 30px;
}
.div1StroHeader2 {
    padding-top: 15px;
}

.div2StroHeader {
    height: 40px;
    margin-top: 35px;
}

.div3StroHeader {
    height: 103px;
    margin-top: 10px;
}

.inputFechaCargaHeaderStro {
    width: 100%;
    height: 48px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EBEBEB;
    border-radius: 19px;
    opacity: 1;
    letter-spacing: 0px;
    color: #7A86A1;
    font-size: 10px;
    font-weight: 300;
}

.selectResponsabilidad {
    width: 217px;
    height: 48px;
    border: 1px solid #EBEBEB;
    border-radius: 20px;
    opacity: 1;
}

.css-13cymwt-control {
    border: none !important;
    padding: 2px;
}

.css-t3ipsp-control {
    border: none !important;
}

.css-t3ipsp-control:hover {
    border: none !important;
}

.btnCoberturaFechaCargaHeaderStro {
    width: 217px;
    height: 48px;
    background: #42C3BA 0% 0% no-repeat padding-box;
    border: 1px solid #EBEBEB;
    border-radius: 20px;
    opacity: 1;
}

.btnCoberturaFechaCargaHeaderStroRojo {
    width: 217px;
    height: 48px;
    background: red 0% 0% no-repeat padding-box;
    border: 1px solid #EBEBEB;
    border-radius: 20px;
    opacity: 1;
}

.inputFechaCargaHeaderStroObservaciones {
    height: 83px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EBEBEB;
    border-radius: 10px;
    opacity: 1;
}

.inputFechaCargaHeaderStroObservaciones:focus {
    border: 1px solid red;
}

.inputCoberturaHeaderStro {
    width: 104px;
    height: 40px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EBEBEB;
    border-radius: 19px;
    opacity: 1;
    letter-spacing: 0px;
    color: #7A86A1;
    font-size: 10px;
    font-weight: 300;
    margin-left: 13.95px;
}

.textFechaCargaStroHeader {
    font-weight: 500;
    font-size: 10px;
    letter-spacing: 0px;
    color: #000000;
    padding-top: 19px;
}

.textFechaSelloStroHeader {
    font-weight: 500;
    font-size: 10px;
    letter-spacing: 0px;
    color: #000000;
    padding-top: 13px;
}


.divInicVigContainer {
    width: 41px;
    height: 50px;
    background: #C7D3E2 0% 0% no-repeat padding-box;
    box-shadow: 3px 5px 30px #6149CD42;
    border-radius: 10px;
    opacity: 1;
    margin-left: 25px;
}

.divFinVigContainer {
    width: 41px;
    height: 50px;
    background: #C7D3E2 0% 0% no-repeat padding-box;
    box-shadow: 3px 5px 30px #6149CD42;
    border-radius: 10px;
    opacity: 1;
    margin-left: 8px;
}

.divStroHeaderContainer {
    width: 41px;
    height: 50px;
    background: #49CBBA 0% 0% no-repeat padding-box;
    box-shadow: 3px -0.3px 30px #6149CD42;
    border-radius: 10px;
    opacity: 1;
    margin-left: 8px;
}

.textInicVig1 {
    font-size: 7px;
    font-weight: 600;
    padding-top: 7px;
    margin: 0;
}

.textInicVig2 {
    font-size: 9px;
    font-weight: bold;
    padding-top: 2px;
}

.textStroHeader1 {
    font-size: 7px;
    color: #1A1A1C;
    font-weight: bold;
    padding-top: 7px;
    margin: 0;
}

.textStroHeader2 {
    font-size: 9px;
    font-weight: bold;
    padding-top: 2px;
    color: #FFFFFF;
}

.textFinVig1 {
    font-size: 7px;
    font-weight: 600;
    padding-top: 7px;
    margin: 0;
}

.textFinVig2 {
    font-size: 9px;
    font-weight: bold;
    padding-top: 2px;
}

.textCoberturaStroHeader {
    padding-left: 30px;
    font-weight: 500;
    font-size: 10px;
    letter-spacing: 0px;
    color: #000000;
    padding-top: 13px;
}

.textResponsabilidadStroHeader {
    padding-left: 24px;
    font-weight: 500;
    font-size: 10px;
    letter-spacing: 0px;
    color: #000000;
    padding-top: 13px;
}

.divCoberturaStroHeaderContainer {
    width: 143px;
    height: 40px;
    background: #49CDBB 0% 0% no-repeat padding-box;
    border: 1px solid #EBEBEB;
    opacity: 1;
    color: #FCFCFC;
    font-size: 10px;
    font-weight: bold;
    margin-left: 11.95px;
    border-radius: 20px;
}

.divResponsabilidadStroHeaderContainer {
    width: 92px;
    height: 40px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EBEBEB;
    border-radius: 19px;
    opacity: 1;
    color: #7A86A1;
    padding-left: 19px;
    font-size: 10px;
    font-weight: 400;
    margin-left: 11.95px;
    border-radius: 20px;
}

.divObservacionesStroHeaderContainer {
    width: 508px;
    height: 103px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EBEBEB;
    border-radius: 19px;
    opacity: 1;
    margin-left: 12.64px;
    padding-top: 10.48px;
    padding-left: 18.91px;
    color: #7A86A1;
    padding-left: 19px;
    font-size: 10px;
    font-weight: 400;
}

.btnSolicitudFecha {
    width: 123px;
    height: 35px;
    background: #62CCE1 0% 0% no-repeat padding-box;
    box-shadow: 1px 8px 29px #695F9724;
    opacity: 1;
    border-radius: 25px;
}

.btnDenunciaInc {
    width: 123px;
    height: 35px;
    background: #C2C2C2 0% 0% no-repeat padding-box;
    box-shadow: 1px 8px 29px #695F9724;
    opacity: 1;
    border-radius: 25px;
    margin-left: 6.73px;
}

.btnCargarStro {
    width: 123px;
    height: 35px;
    background: #49CDBB 0% 0% no-repeat padding-box;
    box-shadow: 1px 8px 29px #695F9724;
    opacity: 1;
    border-radius: 25px;
    margin-right: 34px;
    margin-left: 6.73px;
}

.btnFechasCargasStro {
    font-size: 9px;
}

.textoCarga {
    letter-spacing: 0px;
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    padding-left: 20.45px;
    white-space: wrap;  
    overflow: hidden;        
    text-overflow: ellipsis; 
}

.cronologiaStroContainer {
    width: 100%;
    height: 1270px;
    margin-top: 102px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 2px 13px 54px #695F9714;
    border-radius: 30px;
    opacity: 1;
}


.tituloCronologiaStro {
    margin-top: 46px;
    text-align: left;
    font: normal normal bold 21px/84px Acumin Pro;
    letter-spacing: 0px;
    color: #484848;
    opacity: 1;
}


.imprimirSicobeTablaOtrasDenunciasContainer {
    width: 144px;
    height: 30px;
    /* UI Properties */
    background: #EFFFFA 0% 0% no-repeat padding-box;
    border-radius: 12px;
    opacity: 1;
    color: #434343;
}

.descargarArchivoTablaOtrasDenunciasContainer {
    width: 45px;
    height: 45px;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EBEBEB;
    border-radius: 19px;
    opacity: 1;
}



.contenedorSeccion2Header {
    margin-left: 80%;
    position: absolute;
}
.selectAsignadoTabla {
    width: 160px;
    height: 50px;
    background-color: #42C3BA;
    border-radius: 20px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.selectAsignadoDentroStro {
    width: 100%;
    height: 32px;
    background-color: #42C3BA;
    border-radius: 20px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.selectAsignadoDentroStro2 {
    width: 100%;
    height: 32px;
    background-color: blue;
    border-radius: 20px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.selectSicobeDentroStro {
    width: 100%;
    height: 32px;
    background-color: transparent;
    border: 1px solid #EBEBEB;
    border-radius: 20px;
    opacity: 1;
}
.btnEnviarAsignadoSelect {
    width: 50px;
    height: 50px;
    background-color: #42C3BA;
    border-radius: 50%;
    opacity: 1;
}
.selectEstadoDentroStro {
    width: 140px;
    height: 50px;
    background-color: orange;
    border-radius: 20px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btnEnviarEstadoSelect {
    width: 50px;
    height: 50px;
    background-color: orange;
    border-radius: 50%;
    opacity: 1;
}
.select {
    appearance: none;
    background: transparent;
    border: none;
    color: white;
    text-align: center;
    color: white;
    font-size: 20px;
    width: 70%;
    border: none;
}
.select:focus {
    border-color: none;
    outline: none;      
}
.select option {
    padding: 10px;
    background-color: #fff; 
    color: #333;    
    border: none;
}
.select1 {
    appearance: none;
    color: white;
    color: black;
    width: 217px;
    height: 48px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 19px;
    opacity: 1;
    letter-spacing: 0px;
    color: #7A86A1;
    font-size: 14px;
    font-weight: 300;
    border: 1px solid #EBEBEB;
    padding-left: 16px;
}
.select1 option {
    padding: 10px;
    background-color: #fff; 
    color: #333;    
    border: none;
    font-size: 14px;
}
.select1:focus {
    border: 1px solid #EBEBEB;
    outline: none;
}
.select2A {
    appearance: none;
    background: transparent;
    border: none;
    color: #000000;
    font-size: 12px;
    width: 100%;
    padding-left: 10px;
    padding-right: 5px;
    border: none;
}
.select2A:focus {
    border-color: none;
    outline: none;      
}
.select2A option {
    padding: 10px;
    background-color: #fff; 
    color: #333;    
    border: none;
}



.rowMain {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding-left: 20px;
    padding-right: 20px;
}
.columnMain {
    width: 100%;
}
.rowCronologia {
    padding-left: 20px;
    padding-right: 20px;
}



.selectPrioridadDentroStro {
    width: 100%;
    height: 32px;
    background-color: transparent;
    border: 1px solid #EBEBEB;
    border-radius: 20px;
    opacity: 1;
}



.selectEstadoDentroStro {
    width: 100%;
    height: 32px;
    border-radius: 20px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}