.ampliacionesTexto {
    font-family: 'Manrope', sans-serif;
    font-size: 16px;
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    max-height: 3.2em;
    line-height: 1.6em;
}


.ampliacionesModalTexto {
    font-family: 'Manrope', sans-serif;
    font-size: 16px;
    font-weight: 500;
}
    
.item-bloque {
    display: flex;
    gap: 5px;
    margin-top: 10px;
}