* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    
}

main {
    /* #FCFCFC*/ 
    background: #FCFCFC 0% 0% no-repeat padding-box;
    min-height: 100vh;
}
.headerStro {
    background: #FCFCFC 0% 0% no-repeat padding-box;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-left: 30px;
    padding-right: 30px;
    opacity: 1;
    padding-top: 20px;
    padding-bottom: 20px;
}
.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btnNO {
    border: none;
    background: none;
}

.spanNotifiaciones {
    width: 33px;
    height: 33px;
    background: #F96767 0% 0% no-repeat padding-box;
    border: 2px solid #FBF9FE;
    border-radius: 8px;
    opacity: 1;
    position: relative;
    top: -10px;
}

.react-datepicker-wrapper {
    border: none;
}