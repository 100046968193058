.mensajeCronologiaMensaje {
    width: 1578px;
    height: 114px;
    background: #FFFFFF;
    box-shadow: -6px 3px 6px #00000021;
    opacity: 1;
    padding-top: 23px;
    overflow-y: auto;
    border-right: 1px solid #EBEBEB;
}

.mensajeCronologiaMensaje1 {
    overflow-wrap: break-word;
}
.contenedorPrimeroCronologia {
    margin-left: 29px;
}

.motivoStro {
    width: 50px;
    height: 18px;
    background: #77CEA6 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
}

.motivoAmp {
    width: 47px;
    height: 18px;
    background: #DA68B0 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
}

.motivoCoti {
    width: 47px;
    height: 18px;
    background: #5E5AE8 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
}

.motivoCia {
    width: 47px;
    height: 18px;
    background: #8F8F8F 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
}

.motivoFin {
    width: 47px;
    height: 18px;
    background: #272C2C 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
}

.jerarquiaCrg {
    width: 47px;
    height: 18px;
    background: #2D4357 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    margin-left: 6px;
}

.jerarquiaDen {
    width: 47px;
    height: 18px;
    background: #DD9E67 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    margin-left: 6px;
}


.textoHeaderTablaOtrasDenuncias {
    font-size: 16px !important;
    color: #161616 !important;
}
.textoBodyTablaOtrasDenuncias {
    font-size: 16px !important;
    color: #161616 !important;
    font-weight: 700;
}
.textoBodyTablaOtrasDenuncias2 {
    font-size: 16px !important;
    color: #161616 !important;
    font-weight: 700;
}   

.textoRespon {
    font-size: 11px;
}